<template>
    <b-overlay :show=" !product.CdItemMaterial " class="h-100">
        <b-container id="product" class="p-0 h-100" fluid>
            <b-row style="height: 100%">
                <b-col class="p-0" sm="0" md="0" lg="8" v-if=" isDesktop() ">
                    <b-container id="product-gallery" fluid>
                        <b-row>
                            <b-col>
                                <b-img :src=" product.FotoGaleria " fluid alt="" @error="replaceByDefaultBig"></b-img>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="card-carousel-wrapper">
                                    <div class="card-carousel--nav__left"
                                         @click="moveCarouselGaleria(-1)"
                                         :disabled="atHeadOfListGaleria"
                                    ></div>
                                    <div class="card-carousel">
                                        <div class="card-carousel--overflow-container">
                                            <div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffsetGaleria + 'px' + ')'}">
                                                <div
                                                    class="card-carousel--card"
                                                    v-for=" product in product.TodasFotos "
                                                    v-bind:key="product.URL"
                                                    @click=" viewPhoto( product.URL ) "
                                                >
                                                    <img
                                                        :src="product.URL"
                                                        @error="replaceByDefaultSmall"
                                                        alt="Outra Foto" style="width: 120px"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-carousel--nav__right"
                                         @click="moveCarouselGaleria(1)"
                                         :disabled="atEndOfListGaleria"
                                    ></div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
                <b-col class="p-0" md="12" lg="4">
                    <b-container id="product-detail" fluid>

                        <!-- Imagem do Produto -->
                        <b-row id="product-header">
                            <b-col class="p-0">
                                <b-img v-if=" isDesktop() " :src=" product.FotoPrincipal " fluid alt="" @error="replaceByDefault"></b-img>
                                <b-img v-else :src=" product.FotoGaleria " fluid alt="" @error="replaceByDefaultBig" v-b-toggle.sidebar-product-gallery></b-img>
                            </b-col>
                        </b-row>

                        <!-- Informações do Produto -->
                        <b-row id="product-content">
                            <b-col class="p-0">
                                <b-tabs v-model="tabProduct" fill>
                                    <b-tab id="tab-tamanhos" title="Tamanhos" active>
                                        <b-overlay class="h-100" :show=" product.estoqueNumeracoes.length === 0 ">
                                            <b-container fluid>
                                                <b-row>
                                                    <b-col
                                                        cols="3"
                                                        v-for="tamanho in product.estoqueNumeracoes"
                                                        v-bind:key="tamanho"
                                                        :hidden="
                                                            ( hasMixedSizes && !isMixedSizes(tamanho) ) ||
                                                            ( !hasMixedSizes && isMixedSizes(tamanho) )
                                                        "
                                                    >
                                                        <b-button
                                                            class="btnSize"
                                                            :disabled=" disableByEstoque(product, tamanho) "
                                                            @click=" selectProduct( tamanho ) "
                                                            :class="productSelected.size === tamanho ? 'active':''"
                                                            style="margin-bottom: 8px"
                                                        >
                                                            <img :src=" require( '../../../assets/img/icone-aviao.png' ) "
                                                                 alt="Prateleira Infinita"
                                                                 class="icon-prateleira-infinita"
                                                                 v-if=" isOnlyPrateleiraInfinita( tamanho ) "
                                                            />
                                                            {{ tamanho }}
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-container>
                                        </b-overlay>
                                    </b-tab>
                                    <b-tab id="tab-cores" title="Cores" v-if="product.OutrasCores.length > 0 ">
                                        <div style="width: 100%;">
                                            <template v-if="isDesktop()">
                                                <b-list-group horizontal>
                                                    <b-list-group-item
                                                        v-for=" produto in product.OutrasCores "
                                                        v-bind:key=" produto.CdItemMaterial "
                                                        :to="`/vitrine/${ reseller.VitrineRevendedorID }/${ produto.CdItemMaterial }/`"
                                                    >
                                                        <b-img :src=" produto.URL | resizeImage " style="width: 90%; height: 99%"/>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </template>
                                            <template v-else>
                                                <VueSlickCarousel v-bind="settings">
                                                    <div
                                                        v-for=" product in product.OutrasCores "
                                                        v-bind:key="product.URL"
                                                    >
                                                        <router-link
                                                            tag="div"
                                                            class="produto-photo"
                                                            :to="`/vitrine/${ reseller.VitrineRevendedorID }/${ product.CdItemMaterial }/`"
                                                        >
                                                            <img :src="product.URL" alt="Outra Foto" style="height: 150px"/>
                                                        </router-link>
                                                    </div>
                                                </VueSlickCarousel>
                                            </template>
                                        </div>
                                    </b-tab>
                                    <b-tab id="tab-outras-fotos" title="Fotos" v-if="product.TodasFotos.length > 0 && !isDesktop() ">
                                        <div style="width: 100%">
                                            <VueSlickCarousel v-bind="settings">
                                                <div
                                                    v-for=" product in product.TodasFotos "
                                                    v-bind:key="product.URL"
                                                >
                                                    <div class="produto-photo" v-b-toggle.sidebar-product-gallery>
                                                        <img :src="product.URL" alt="Outra Foto" style="height: 150px"/>
                                                    </div>
                                                </div>
                                            </VueSlickCarousel>
                                        </div>
                                    </b-tab>
                                    <b-tab id="tab-descricao" title="Descrição">
                                        <div style="height: 100%; width: 100%; overflow: auto" v-html="product.Descricao"></div>
                                    </b-tab>
                                </b-tabs>
                            </b-col>
                        </b-row>

                        <!-- Footer -->
                        <b-row id="product-footer">
                            <b-col class="p-0">
                                <b-container>
                                    <b-row>
                                        <b-col>
                                            <p class="nome_produto text-truncate">{{ product.Nome }}</p>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="5">
                                            <p class="preco_anterior" style="height: 25px">
                                                <template v-if="PrecoCheio > Preco">
                                                    {{ PrecoCheio | numberToBRL }}
                                                </template>
                                            </p>
                                            <p class="preco_atual">{{ Preco | numberToBRL }}</p>
                                        </b-col>
                                        <b-col cols="7" class="text-right pl-0">
                                            <b-button
                                                class="btnComprar cursor-pointer"
                                                :disabled="!productSelected.permitirCompra"
                                                @click="addToBag( true )"
                                            >
                                                COMPRAR
                                            </b-button>
                                            <b-button
                                                id="btnAddToBag"
                                                class="btnComprar cursor-pointer"
                                                :disabled="!productSelected.permitirCompra"
                                                v-b-tooltip.click
                                                title="Item adicionado à sacola"
                                                @click="addToBag( false )"
                                            >
                                                <b-img :src=" getIconBag(1) " alt=" Adicionar a sacola "></b-img>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-col>
                        </b-row>

                    </b-container>
                </b-col>
            </b-row>
        </b-container>
        <b-sidebar id="sidebar-product-gallery" right shadow sidebar-class="border-right border-danger">

            <template v-if="this.product.FotoGaleriaZoom">
                <pinch-zoom ref="produto_pinch_zoom" :limitZoom=" 100 ">
                    <b-img :src=" this.product.FotoGaleriaZoom " fluid alt=""></b-img>
                </pinch-zoom>
            </template>

            <template #footer>

                <template v-if="product.TodasFotos.length > 0">
                    <VueSlickCarousel v-bind="settings">
                        <div
                            v-for=" product in product.TodasFotos "
                            v-bind:key="product.URL"
                        >
                            <div class="produto-photo" @click=" viewPhoto( product.URL ) ">
                                <img :src="product.URL" alt="Outra Foto" style="height: 150px"/>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </template>
                <p>Use os dois dedos para aumentar e diminuir o zoom</p>
            </template>
        </b-sidebar>
    </b-overlay>
</template>
<style lang="scss">

// Fix - Layout responsivo
.row
{
    margin: 0
}

#product
{
    #product-detail
    {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #F6F6F6;
        padding: 0;

        #product-header
        {
            background-color: white;
            border-bottom-right-radius: 40px;
            border-bottom-left-radius: 40px;
            height: 200px;
            flex: 0 0 200px;

            div
            {
                display: flex;
                align-items: center;
                justify-content: center;

                img
                {
                    height: 200px;
                }
            }
        }

        #product-content
        {
            background-color: #F6F6F6;
            flex: 1;
            overflow: auto;

            .tabs
            {
                height: 100%;

                ul.nav
                {
                    border: none;

                    .nav-item
                    {
                        a
                        {
                            font-size: 18px;
                            background-color: transparent;
                            border: none;
                            padding: 10px 0;
                            color: gray;
                        }

                        a.active
                        {
                            color: var(--text-color);
                            text-decoration: underline;
                        }
                    }
                }

                .tab-content
                {
                    height: 80%;

                    .tab-pane
                    {
                        height: 100% !important;
                        overflow: auto;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        .card-carousel-wrapper
                        {
                            width: 100%;
                        }
                    }

                    #tab-tamanhos
                    {
                        .container-fluid
                        {
                            display: flex;
                            justify-content: center;

                            .row
                            {
                                width: 100%;

                                .btnSize
                                {
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                        }
                    }

                    #tab-descricao
                    {
                        font-size: 12px;
                        text-align: center;
                        padding: 10px;
                    }
                }
            }
        }

        #product-footer
        {
            background-color: white;
            border-top-right-radius: 40px;
            border-top-left-radius: 40px;
            height: 200px;
            flex: 0 0 200px;

            p.nome_produto
            {
                text-align: center;
                padding-top: 20px;
                font-size: 20px;
            }

            p.preco_anterior,
            p.preco_atual
            {
                margin-bottom: 0;
            }

            p.preco_atual
            {
                font-size: 22px;
            }

            .col-5
            {
                padding: 0;
            }

            .col-7
            {
                padding: 0;

                .btnComprar
                {
                    height: 50px;
                    padding: 5px;
                    margin-right: 5px;
                    width: 100px !important;
                }

                #btnAddToBag
                {
                    height: 50px !important;
                    width: 50px !important;
                    padding: 5px !important;

                    img
                    {
                        width: 30px;
                    }
                }
            }

        }
    }
}

.is-desktop
{
    #product
    {
        #product-gallery
        {
            height: 100%;
            display: flex;
            flex-direction: column;

            .row:first-of-type
            {
                flex: 1;

                .col
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img
                    {
                        height: 270px;
                    }
                }
            }

            .row:last-of-type
            {
                flex: 0 0 150px;

                .card-carousel-wrapper
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 20px 0 40px;
                    color: #666a73;

                    .card-carousel
                    {
                        display: flex;
                        justify-content: center;
                        width: 410px;

                        &--overflow-container
                        {
                            overflow: hidden;
                        }

                        &--nav__left,
                        &--nav__right
                        {
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            padding: 10px;
                            box-sizing: border-box;
                            border-top: 2px solid black;
                            border-right: 2px solid black;
                            cursor: pointer;
                            margin: 0 20px;
                            transition: transform 150ms linear;

                            &[disabled]
                            {
                                opacity: 0.2;
                                border-color: black;
                            }
                        }

                        &--nav__left
                        {
                            transform: rotate(-135deg);

                            &:active
                            {
                                transform: rotate(-135deg) scale(0.9);
                            }
                        }

                        &--nav__right
                        {
                            transform: rotate(45deg);

                            &:active
                            {
                                transform: rotate(45deg) scale(0.9);
                            }
                        }

                        &--nav__left[disabled],
                        &--nav__right[disabled]
                        {
                            opacity: 0;
                        }

                        .card-carousel-cards
                        {
                            display: flex;
                            transition: transform 150ms ease-out;
                            transform: translatex(0px);

                            .card-carousel--card
                            {
                                margin: 0 10px;
                                cursor: pointer;
                                box-shadow: 0 4px 15px 0 rgba(40, 44, 53, .06), 0 2px 2px 0 rgba(40, 44, 53, .08);
                                background-color: #fff;
                                z-index: 3;
                                margin-bottom: 2px;
                                border-radius: 20px;

                                &:first-child
                                {
                                    margin-left: 0;
                                }

                                &:last-child
                                {
                                    margin-right: 0;
                                }

                                img
                                {
                                    vertical-align: bottom;
                                    transition: opacity 150ms linear;
                                    user-select: none;
                                    border-radius: 20px;

                                    &:hover
                                    {
                                        opacity: 0.5;
                                    }
                                }

                                &--footer
                                {
                                    border-top: 0;
                                    padding: 7px 15px;

                                    p
                                    {
                                        padding: 3px 0;
                                        margin: 0;
                                        margin-bottom: 2px;
                                        font-size: 19px;
                                        font-weight: 500;
                                        color: #2c3e50;
                                        user-select: none;

                                        &.tag
                                        {
                                            font-size: 11px;
                                            font-weight: 300;
                                            padding: 4px;
                                            background: rgba(40, 44, 53, .06);
                                            display: inline-block;
                                            position: relative;
                                            margin-left: 4px;
                                            color: #666a73;

                                            &:before
                                            {
                                                content: "";
                                                float: left;
                                                position: absolute;
                                                top: 0;
                                                left: -12px;
                                                width: 0;
                                                height: 0;
                                                border-color: transparent rgba(40, 44, 53, .06) transparent transparent;
                                                border-style: solid;
                                                border-width: 8px 12px 12px 0;
                                            }

                                            &.secondary
                                            {
                                                margin-left: 0;
                                                border-left: 1.45px dashed white;

                                                &:before
                                                {
                                                    display: none !important;
                                                }
                                            }

                                            &:after
                                            {
                                                content: "";
                                                position: absolute;
                                                top: 8px;
                                                left: -3px;
                                                float: left;
                                                width: 4px;
                                                height: 4px;
                                                border-radius: 2px;
                                                background: white;
                                                box-shadow: -0px -0px 0px #004977;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #product-detail
        {
            display: block;
            border-left: 10px solid #f6f6f6;
            border-right: 10px solid #f6f6f6;

            #product-header
            {
                height: 150px !important;
                flex: 0 0 150px !important;

                img
                {
                    height: 100px;
                }
            }

            #product-content
            {
                overflow: hidden;
                height: 310px;
                max-height: 310px;

                .nav-tabs
                {
                    .nav-item
                    {
                        .nav-link
                        {
                            color: black;
                        }
                    }
                }

                .tab-content
                {
                    padding-right: 5px;
                    height: 265px;

                    .tab-pane
                    {
                        padding: 0;
                        height: 410px;
                        max-height: 410px;
                    }

                    #tab-tamanhos
                    {
                        .container-fluid
                        {
                            .btnSize
                            {
                                height: 55px !important;
                                width: 55px !important;
                            }
                        }
                    }

                    #tab-cores
                    {
                        div:first-of-type
                        {
                            height: 260px;
                            // Galeria
                            @mixin n-columns($min-width, $gutter, $last-equal: false, $max-cols: 5)
                            {
                                .list-group
                                {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-left: -$gutter;
                                    margin-top: -$gutter;
                                    width: 100%;
                                    max-height: 410px;

                                    .list-group-item
                                    {
                                        padding: 0;
                                        flex: 1 0 $min-width;
                                        margin-left: $gutter;
                                        margin-top: $gutter;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border: 1px solid rgba(0, 0, 0, 0.125);
                                        height: 70px;
                                        border-radius: 15px;

                                        img
                                        {
                                            border-radius: 14px;
                                        }

                                        @if $last-equal
                                        {
                                            @for $i from 2 through $max-cols
                                            {
                                                $screen-width: ($min-width*$i)+($gutter*$i);
                                                $column-width: (100%/$i);
                                                @media (min-width: $screen-width)
                                                {
                                                    max-width: calc(#{$column-width} - #{$gutter});
                                                }
                                            }

                                            $column-width: (100%/$max-cols);
                                            @media (min-width: $min-width*$max-cols)
                                            {
                                                min-width: calc(#{$column-width} - #{$gutter});
                                            }
                                        }
                                    }
                                }
                            }
                            @include n-columns(350px, 10px, true, 2);
                        }
                    }
                }
            }

            #product-footer
            {
                height: 140px;
                flex: 0 0 140px;

                .nome_produto
                {
                    font-size: 12px;
                }
            }
        }
    }
}

.slick-slider
{
    overflow: hidden;
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;

    .slick-list
    {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0 0 0 5px;
        transform: translateZ(0);
        height: 100px;

        .slick-track
        {
            position: relative;
            top: 0;
            left: 0;
            display: block;

            .slick-slide
            {
                float: left;
                height: 100%;
                min-height: 1px;

                &.slick-current
                {
                    .produto-photo
                    {
                        //border: 1px solid red !important;
                        box-shadow: 0 3px 6px #00000029;
                    }
                }

                .produto-photo
                {
                    padding: 10px;
                    background-color: white;
                    border-radius: 15px;
                    margin-right: 10px;

                    img
                    {
                        width: 100%;
                    }
                }
            }
        }

        .slick-track::before
        {
            display: table;
            content: "";
        }

        .slick-track::after
        {
            display: table;
            content: "";
        }
    }
}

#sidebar-product-gallery
{
    width: 100% !important;
    height: 100% !important;
    margin-top: -60px !important;

    .b-sidebar-body
    {
        display: flex;
        align-items: center;
        justify-content: center;

        .pinch-zoom-wrapper
        {
            background-color: #f8f9fa !important;
        }
    }

    .b-sidebar-footer
    {
        p
        {
            font-size: 10px;
            text-align: center;
            line-height: 10px;
            margin-top: 1rem;
        }
    }
}

</style>
<script>

import Vue from 'vue'
import store from '@/store'
import {Utils} from '@/components/utils'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import PinchZoom from 'vue-pinch-zoom';

Vue.component('pinch-zoom', PinchZoom);

export default {
    name: 'product',
    mixins: [Utils],
    components: {VueSlickCarousel},
    props: ['reseller', 'product', 'hasMixedSizes'],
    data() {
        return {

            settings: {
                'arrows': false,
                'dots': false,
                'focusOnSelect': true,
                'infinite': true,
                'slidesToShow': 3,
                'slidesToScroll': 3,
                'touchThreshold': 5,
            },

            destacarNumeracao: false,
            showLoading: true,
            tabProduct: 0,
            productSelected: {
                permitirCompra: true,
                VitrineResellerId: null,
                VitrineRevendedorID: null,
                storeCode: null,
                CodLoja: null,
                ean: null,
                quantidade: null,
                CdItemMaterial: null,
                size: null,
                Nome: null,
                Preco: null,
                FotoPrincipal: null,
                Numeracao: null,
            },
            // Galeria
            currentOffsetGaleria: 0,
            windowSizeGaleria: 3,
            paginationFactorGaleria: 130,
        }
    },
    methods: {
        disableByEstoque(produto, numeracao) {
            let estoqueDisponivel = Vue.lodash.find(produto.estoque, estoque => {
                return (
                    estoque.Size === numeracao &&
                    estoque.Quantity > 0
                )
            })

            if (estoqueDisponivel === undefined) {
                return true
            }
            else {
                // Verificar se é Prateleira Infinita
                // Se for, verificar se PrecoEcommerce > 0
                if (this.isOnlyPrateleiraInfinita(numeracao)) {
                    return produto.PrecoEcommerce === 0;
                }
            }

            return false
        },
        selectProduct(numeracao) {

            // Ler estoque
            const estoques = Vue.lodash.filter(this.product.estoque, estoque => {
                return (
                    estoque.Size === numeracao &&
                    estoque.Quantity > 0
                )
            })

            // Objeto Loja
            let Loja = {}

            // Verificar se o produto está no Ecommerce
            if (this.isOnlyPrateleiraInfinita(numeracao)) {
                Loja = Vue.lodash.find(estoques, estoque => {
                    return estoque.StoreCode === store.getters.storeCodeEcommerce
                })
            }
            else {
                // Verificar se o produto está na loja Matriz
                const LojaMatriz = Vue.lodash.find(estoques, estoque => {
                    return estoque.StoreCode === this.reseller.CodLoja
                })
                if (Vue.lodash.isEmpty(LojaMatriz)) {
                    // Verificar se oproduto está na loja afilhada
                    // Ignorando o Ecommerce
                    Loja = Vue.lodash.find(estoques, estoque => {
                        return (
                            estoque.StoreCode !== store.getters.storeCodeEcommerce &&
                            estoque.Quantity > 0
                        )
                    })
                }
                else {
                    // Usar loja matriz
                    Loja = LojaMatriz
                }
            }
            if (Vue.lodash.isEmpty(Loja)) {
                alert('Não foi possível localizar loja')
            }
            else {

                // Liberar compra
                this.destacarNumeracao = false;
                this.$root.$emit('bv::hide::tooltip')
                setTimeout(() => {
                    // Sidebar
                    const sidebarProduct = document.getElementById('sidebar-product');
                    if (sidebarProduct !== null) {
                        sidebarProduct.classList.remove('destacar-numeracao');
                    }

                    // Modal Product
                    const modalProduct = document.getElementById('modal-product');
                    if (modalProduct !== null) {
                        modalProduct.classList.remove('destacar-numeracao')
                    }
                }, 200)

                // Criar registro
                this.productSelected = {
                    permitirCompra: true,
                    estoque: this.product.estoque,
                    VitrineResellerId: (this.reseller.VitrineRevendedorID).toString(),
                    VitrineRevendedorID: (this.reseller.RevId).toString(),
                    storeCode: (this.reseller.CodLoja).toString(),
                    CodLoja: Loja.StoreCode,
                    ean: (Loja.EAN).toString(),
                    quantidade: 1,
                    CdItemMaterial: this.product.CdItemMaterial,
                    size: Loja.Size,
                    Nome: this.product.Nome,
                    Preco: this.isOnlyPrateleiraInfinita(numeracao) ? this.product.PrecoEcommerce : this.product.Preco,
                    PrecoCheio: this.product.PrecoCheio,
                    FotoPrincipal: this.product.FotoPrincipal,
                    Numeracao: numeracao,
                }
            }

        },
        addToBag(closeModal = true) {

            // Ir para primeira aba
            this.tabProduct = 0
            // Sidebar
            const sidebarProduct = document.getElementById('sidebar-product');
            // Modal Product
            const modalProduct = document.getElementById('modal-product');

            // Enviar para o carrinho
            if (Vue.lodash.isEmpty(this.productSelected.Numeracao)) {

                if (sidebarProduct !== null) {
                    sidebarProduct.classList.add('destacar-numeracao')
                }

                if (modalProduct !== null) {
                    modalProduct.classList.add('destacar-numeracao')
                }

                this.destacarNumeracao = true;
            }
            else {

                if (sidebarProduct !== null) {
                    sidebarProduct.classList.remove('destacar-numeracao')
                }

                if (modalProduct !== null) {
                    modalProduct.classList.remove('destacar-numeracao')
                }

                this.destacarNumeracao = false;
                // Adicionar produto ao carrinho
                store.dispatch('addToBag', this.productSelected)
                    .then(() => {

                        // Adicionar ao GTM
                        this.$gtm.trackEvent({
                            'event': 'addToCart',
                            'ecommerce': {
                                'currencyCode': 'BRL',
                                'add': {
                                    'products': [
                                        {
                                            'name': this.product.Nome,
                                            'id': this.product.CdItemMaterial,
                                            'price': this.product.Preco,
                                            'brand': this.getMarcaByID(this.reseller.MarcaIdo),
                                            // 'category': this.VitrineResellerId,
                                            'variant': this.productSelected.Numeracao,
                                            'quantity': 1,
                                        },
                                    ],
                                },
                            }
                        })

                        // Ir para vitrine
                        this.$emit('reloadBag', closeModal)

                        // Ocultar tooltip
                        setTimeout(() => {
                            this.$root.$emit('bv::hide::tooltip')
                        }, 2000)
                    })
                    .catch((error) => {
                        console.log('error', error)
                    })
            }
        },
        isOnlyPrateleiraInfinita(numeracao) {
            // Ler estoque para encontrar todas as lojas que tem numeracao e estoque disponivel
            const estoqueDisponivel = Vue.lodash.filter(this.product.estoque, estoque => {
                return (
                    estoque.Size === numeracao &&
                    estoque.Quantity > 0
                )
            })
            // Caso venha apenas um registro, verificar se é ecommerce
            if (estoqueDisponivel.length === 1) {
                // Leitura se é ecommerce
                const ecommerce = Vue.lodash.find(estoqueDisponivel, estoque => {
                    return estoque.StoreCode === store.getters.storeCodeEcommerce
                })
                // é ecommerce ?
                return !Vue.lodash.isEmpty(ecommerce)
            }
            // default
            return false
        },
        viewPhoto(url) {

            // Atualizar imagem
            this.product.FotoGaleria = url
            this.product.FotoGaleriaZoom = url

            // Resetar zoom
            if (this.$refs.produto_pinch_zoom.getScale() !== 1) {
                this.$refs.produto_pinch_zoom.toggleZoom()
            }

        },
        viewProduct(CdItemMaterial) {
            this.$router.push({
                path: `/vitrine/${this.reseller.VitrineRevendedorID}/${CdItemMaterial}`,
            })
        },
        moveCarouselGaleria(direction) {
            // Find a more elegant way to express the :style. consider using props to make it truly generic
            if (direction === 1 && !this.atEndOfListGaleria) {
                this.currentOffsetGaleria -= this.paginationFactorGaleria
            }
            else if (direction === -1 && !this.atHeadOfListGaleria) {
                this.currentOffsetGaleria += this.paginationFactorGaleria
            }
        },
    },
    computed: {
        Preco() {
            const produto = Vue.lodash.isNull(this.productSelected.CodLoja) ? this.product : this.productSelected
            return produto.Preco
        },
        PrecoCheio() {
            if (Vue.lodash.isNull(this.product.PrecoCheio)) {
                return 0
            }
            return this.product.PrecoCheio
        },
        atEndOfListGaleria() {
            return this.currentOffsetGaleria <= (this.paginationFactorGaleria * -1) * (this.product.TodasFotos.length - this.windowSizeGaleria)
        },
        atHeadOfListGaleria() {
            return this.currentOffsetGaleria === 0
        },
    },
    watch: {
        '$route.params.CdIteMaterial': function (CdIteMaterial) {
            if (Vue.lodash.isEmpty(CdIteMaterial) === false) {

                // Limpar item selecionado
                this.productSelected = {
                    permitirCompra: true,
                    VitrineResellerId: null,
                    VitrineRevendedorID: null,
                    storeCode: null,
                    CodLoja: null,
                    ean: null,
                    quantidade: null,
                    CdItemMaterial: null,
                    size: null,
                    Nome: null,
                    Preco: null,
                    FotoPrincipal: null,
                    Numeracao: null,
                }

                // Ir para primeira aba
                this.tabProduct = 0
            }
        }
    },
    mounted() {
        // Sidebar
        const sidebarProduct = document.getElementById('sidebar-product');
        if (sidebarProduct !== null) {
            sidebarProduct.classList.remove('destacar-numeracao');
        }

        // Modal Product
        const modalProduct = document.getElementById('modal-product');
        if (modalProduct !== null) {
            modalProduct.classList.remove('destacar-numeracao')
        }
        const selfProductSelected = this.selectProduct
        this.$watch('product.estoqueNumeracoes', (numeracoes) => {
            if (numeracoes.length === 1 && numeracoes[0] === 'UN') {
                selfProductSelected('UN')
            }
        })
    }
}
</script>
